import React from "react";
import PropTypes from "prop-types";
import counterpart from "counterpart";
import { Button } from "@shift-technology/shift-design-system";
import classNames from "classnames";
import bind from "memoize-bind";

class LoadingButton extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = { status: "ready" };
    }

    componentWillUnmount() {
        this.isUnmounted = true;
    }

    setMountedState(...args) {
        if (!this.isUnmounted) {
            this.setState(...args);
        }
    }

    onUpdate(success) {
        return new Promise((resolve) => {
            if (success) {
                this.setMountedState({ status: "success" });
                setTimeout(() => {
                    if (this.props.multiClickEnabled) {
                        this.setMountedState({ status: "ready" });
                    }

                    resolve();
                }, 1000);
            } else {
                this.setMountedState({ status: "failure" });
                setTimeout(() => {
                    this.setMountedState({ status: "ready" });
                    resolve();
                }, 1000);
            }
        });
    }

    onClick() {
        if (this.state.status !== "loading") {
            this.setState({ status: "loading" });
            Promise.method(this.props.onClick)()
                .always(bind(this.onUpdate, this))
                .then(this.props.onSuccess)
                .catch(this.props.onFailure);
        }
    }

    getText() {
        switch (this.state.status) {
            case "loading":
                return this.props.loadingMsg ?? counterpart("common.loading");
            case "success":
                return this.props.successMsg ?? this.props.children;
            default:
                return this.props.children;
        }
    }

    render() {
        return (
            <Button
                {...this.props}
                className={classNames(this.props.className, { "shake-error": this.state.status === "failure" })}
                icon={this.state.status === "success" && "circle-check"}
                isLoading={this.state.status === "loading"}
                onClick={bind(this.onClick, this)}
                isDisabled={this.state.status !== "ready" || this.props.disabled}
            >
                {this.getText()}
            </Button>
        );
    }
}

LoadingButton.displayName = "LoadingButton";

LoadingButton.propTypes = {
    ...Button.propTypes,
    disabled: PropTypes.any,
    className: PropTypes.string,
    loadingMsg: PropTypes.string,
    multiClickEnabled: PropTypes.bool,
    onFailure: PropTypes.func,
    onSuccess: PropTypes.func,
    successMsg: PropTypes.string,
};

LoadingButton.defaultProps = {
    multiClickEnabled: false,
    onFailure: () => {},
    onSuccess: () => {},
};

export default LoadingButton;
